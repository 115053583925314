import React, { useContext, useRef, useState, useEffect } from 'react';
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonIcon,
  IonPage,
  IonSegmentButton,
  IonLabel,
  IonSegment,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonList,
  // IonCard,
} from '@ionic/react';
import { locationOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

import RestaurantTile from './RestaurantTile';
import RestaurantSkeletonTile from './RestaurantSkeletonTile';
import RestaurantModal from './RestaurantModal';
import Header from '../header/Header';
import AddressFields from '../addressFields/AddressFields';
import { AuthContext } from '../authentication/AuthContext';
import MatchmakingContext from '../../global/MatchmakingContext';
import { AppContext, AppDispatchContext, ACTIONS } from '../../global/AppContext';
import { updateAddress, getPastAddresses } from '../../global/request/user';
// import RestaurantMap from '../../googleMapsApi/RestaurantMap';

import '../../styles/restaurant/Restaurant.scss';
import useRestaurants from '../../global/useRestaurants';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

function Restaurant() {
  const appCtx = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars
  const authCtx = useContext(AuthContext);
  const appDispatchCtx = useContext(AppDispatchContext);
  const history = useHistory();

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [reload, restaurants] = useRestaurants();
  const [segmentValue, setSegmentValue] = useState('inPerson');
  const [searchTerm, setSearchTerm] = useState('');
  const modalRef = useRef(null);
  const { dispatch } = useContext(MatchmakingContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [addressDetails, setAddressDetails] = useState({
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
  });
  const [pastAddresses, setPastAddresses] = useState([]);
  const bearerToken = authCtx.tokens.idToken;
  // eslint-disable-next-line no-unused-vars

  const allowedCities = restaurants
    .map(restaurant => restaurant.address.split(', ')[1])
    .filter((city, index, self) => city && self.indexOf(city) === index);

  const fetchPastAddresses = async () => {
    setPastAddresses(await getPastAddresses(bearerToken));
  };

  useEffect(() => {
    if (history.location.state && history.location.state.tab) {
      setSegmentValue(history.location.state.tab);
    }
  }, [history.location.state]);

  const handleAddressSubmit = async () => {
    const deliveryRestaurant = restaurants.find(rest => rest.id === 64);
    if (!deliveryRestaurant) return;

    const trimmedApt = addressDetails.apt?.replace(' ', '');
    const formattedAddress = `${addressDetails.streetAddress}${trimmedApt ? ` ${trimmedApt}` : ''}, ${
      addressDetails.city
    }, ${addressDetails.state} ${addressDetails.zip}`;

    try {
      await updateAddress(bearerToken, formattedAddress);

      appDispatchCtx.dispatch({
        action: ACTIONS.SET_RESTAURANT,
        restaurant: { ...deliveryRestaurant, address: formattedAddress },
      });
      setSelectedRestaurant({ ...deliveryRestaurant, address: formattedAddress });
      dispatch({ type: 'TRIGGER_GET_LIVE_BETS' });
      fetchPastAddresses();
    } catch (error) {
      console.error('Failed to update address for delivery:', error);
    }

    setTimeout(() => {
      history.push('/app/home');
    }, 1000);
  };

  const handleSelectRestaurant = rest => {
    dispatch({
      type: 'SET_SELECTED_RESTAURANT',
      payload: rest,
    });
    setSelectedRestaurant(rest);
    modalRef.current.present();
  };

  const handleAddressClick = async address => {
    const deliveryRestaurant = restaurants.find(rest => rest.id === 64);
    if (!deliveryRestaurant) return;

    try {
      await updateAddress(bearerToken, address);

      appDispatchCtx.dispatch({
        action: ACTIONS.SET_RESTAURANT,
        restaurant: { ...deliveryRestaurant, address },
      });
      setSelectedRestaurant({ ...deliveryRestaurant, address });
      dispatch({ type: 'TRIGGER_GET_LIVE_BETS' });
      fetchPastAddresses();
    } catch (error) {
      console.error('Failed to update address for delivery:', error);
    }

    setTimeout(() => {
      history.push('/app/home');
    }, 1000);
  };

  useEffect(() => {
    fetchPastAddresses();
  }, []);

  useEffect(() => {
    if (appCtx.restaurant) {
      setSelectedRestaurant(appCtx.restaurant);
    }
  }, [appCtx.restaurant]);

  const sortRestaurants = rests => {
    let selectedRestaurantName;

    if (appCtx.restaurant) {
      selectedRestaurantName = appCtx.restaurant.name;
    }

    const sortedOthers = rests
      .filter(rest => selectedCity === 'All' || rest.address.includes(selectedCity))
      .filter(rest => rest.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter(rest => rest.name !== selectedRestaurantName)
      .sort((a, b) => a.name.localeCompare(b.name));

    const sortedWithSelectedFirst = appCtx.restaurant ? [appCtx.restaurant, ...sortedOthers] : sortedOthers;

    return sortedWithSelectedFirst
      .filter(
        rest =>
          (rest.dineIn === true && segmentValue === 'inPerson') ||
          (rest.delivery === true && segmentValue === 'delivery'),
      )
      .filter(rest => rest.id !== 44 && rest.id !== 64);
  };

  return (
    <IonPage>
      <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search places..." />
      <IonContent id="restaurant-page">
        {/* <IonCard className="restaurant-map">
          <RestaurantMap restaurants={sortRestaurants(restaurants)} />
        </IonCard> */}
        <IonSegment
          mode="md"
          className="restaurant-segment"
          value={segmentValue}
          onIonChange={e => setSegmentValue(e.detail.value)}
        >
          <IonSegmentButton className="restaurant-segment-button" value="inPerson">
            <IonLabel>In-person</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton className="restaurant-segment-button" value="delivery">
            <IonLabel>Delivery</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {segmentValue === 'delivery' && (
          <>
            <AddressFields
              addressDetails={addressDetails}
              setAddressDetails={setAddressDetails}
              setIsFormValid={setIsFormValid}
              className="ion-padding"
            />
            <IonButton className="delivery-save-button" onClick={handleAddressSubmit} disabled={!isFormValid}>
              Save
            </IonButton>
            {pastAddresses.length > 0 && (
              <>
                <h1 className="past-addresses-text">Past Addresses</h1>
                {pastAddresses.map(address => (
                  <IonButton
                    className="past-address ion-text-wrap"
                    onClick={() => handleAddressClick(address)}
                    key={address}
                  >
                    {address}
                  </IonButton>
                ))}
              </>
            )}
          </>
        )}
        {segmentValue === 'inPerson' && (
          <IonItem lines="none">
            <IonIcon icon={locationOutline} slot="start" />
            <h3 className="venu-text">{appCtx.restaurant ? appCtx.restaurant.name : 'Select a location'}</h3>
          </IonItem>
        )}
        {segmentValue === 'inPerson' && (
          <IonList>
            <IonItem>
              <IonSelect
                value={selectedCity}
                onIonChange={e => setSelectedCity(e.detail.value)}
                aria-label="Cities"
                interface="popover"
                placeholder="Select city"
              >
                <IonSelectOption placeholder="select a city" value="All">
                  All
                </IonSelectOption>
                {allowedCities.map(city => (
                  <IonSelectOption key={city} value={city}>
                    {city}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
        )}

        <IonGrid id="restaurant-grid">
          <IonRow>
            {console.log('Rendering restaurant tiles for:', sortRestaurants(restaurants))}
            {sortRestaurants(restaurants).map(rest => (
              <IonCol size="1" key={generateUniqueKey()}>
                <RestaurantTile
                  name={rest.name}
                  distance={0}
                  rating={rest.rating}
                  img={rest.icon}
                  onClick={() => {
                    handleSelectRestaurant(rest);
                    setSelectedRestaurant(rest);
                    modalRef.current.present();
                  }}
                />
              </IonCol>
            ))}
            {restaurants.length === 0 &&
              Array(7)
                .fill()
                .map(() => (
                  <IonCol size="1" key={generateUniqueKey()}>
                    <RestaurantSkeletonTile />
                  </IonCol>
                ))}
          </IonRow>
        </IonGrid>
      </IonContent>
      <RestaurantModal ref={modalRef} restaurant={selectedRestaurant} />
    </IonPage>
  );
}

export default Restaurant;
