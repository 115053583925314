/** @module global/request/user */
import RequestUtility from './request';

const SERVICE_URL =
  window.location.hostname === 'www.getfoodfight.link'
    ? 'https://d2if4tc6g5.execute-api.us-east-1.amazonaws.com/prod'
    : 'https://v5j4xszs7i.execute-api.us-east-1.amazonaws.com/prod';
const util = new RequestUtility(SERVICE_URL, {
  defaultMethod: 'GET',
});

// avoid error where friends field was initialized as array but changed to string in db
const getFriendsFromProfile = profile => {
  if (typeof profile.friends === 'string' && profile.friends.trim() !== '') {
    return profile.friends.split(',');
  }
  if (Array.isArray(profile.friends)) {
    return profile.friends;
  }
  return [];
};

/**
 *
 * @param {Object} profile
 * @returns {types.Profile}
 */
const transformProfile = profile => ({
  id: profile.user_id || 'Unset value',
  username: profile.username || 'Unset value',
  name: profile.name || 'Unset value',
  email: profile.email || 'Unset value',
  phoneNumber: profile.phone_number || 'Unset value',
  bio: profile.bio || 'Unset value',
  // I think friendCount isn't stored in backend
  // friendsCount: profile.friendsCount || 0,
  streak: profile.streak || 0,
  visited: profile.visited || 0,
  // favSports: (profile.fav_sports && [profile.fav_sports]) || [],
  favSports: typeof profile.fav_sports === 'string' ? profile.fav_sports.split(',') : [],
  totalFights: profile.fight_count || 0,
  wins: profile.win_count || 0,
  losses: profile.loss_count || 0,
  ties: profile.tie_count || 0,
  deliveryRestaurant: profile.delivery_restaurant || 'Unset value',
  deliveryRestaurantId: profile.delivery_restaurant_id || 0,
  addresses: profile.addresses || [],
  addressSet: profile.address_set || 0,
  icon: profile.icon || null,
  birthdate: profile.birthdate || 'Unset value',
  friends: getFriendsFromProfile(profile),
  devices: profile.devices,
  review: profile.sent_review,
});

/**
 * Sends a request to the server to fetch the user's profile.
 * @param {string} bearerToken The bearer token to use for authentication. This should be the id token obtained from authentication
 * @returns
 */
async function getProfile(bearerToken) {
  const response = await util.authorizedRequest('user/profile', {
    bearerToken,
  });
  return transformProfile(response);
}

async function updateProfile(bearerToken, profile) {
  const response = await util.authorizedRequest('user/profile', { method: 'PUT', bearerToken }, profile);
  console.log('Response', response);
}

// XXX: temporary dev function - endpoint only works for admin accounts
async function allUsers(bearerToken) {
  const response = await util.authorizedRequest('user/profile/~', {
    bearerToken,
  });
  return response.map(profile => transformProfile(profile));
}

async function deleteUserData(bearerToken) {
  // eslint-disable-next-line no-unused-vars
  const response = await util.authorizedRequest('user/profile', {
    method: 'DELETE',
    bearerToken,
  });
}

async function updateAddress(bearerToken, address) {
  const addressEncoded = encodeURIComponent(address);

  try {
    const response = await util.authorizedRequest(`user/profile/address/${addressEncoded}`, {
      method: 'POST',
      bearerToken,
    });

    return response;
  } catch (error) {
    console.error('Failed to update address:', error);
    throw error;
  }
}

async function getPastAddresses(bearerToken) {
  const response = await util.authorizedRequest('user/addresses', {
    bearerToken,
  });
  return response;
}

async function getReferalLink(bearerToken) {
  const response = await util.authorizedRequest('user/referral_signup', {
    bearerToken,
  });
  return response;
}

async function getFriendsProfile(bearerToken, user_id) {
  const response = await util.authorizedRequest(`user/profile/${user_id}`, {
    bearerToken,
  });
  return transformProfile(response);
}

async function incrementReferralCount(bearerToken, referralId) {
  const endpoint = `user/referral/${referralId}`;
  const response = await util.authorizedRequest(endpoint, {
    method: 'PUT',
    bearerToken,
  });

  return response;
}

async function updateLocation(bearerToken, longitude, latitude) {
  const longitudeEncoded = encodeURIComponent(longitude);
  const latitudeEncoded = encodeURIComponent(latitude);
  try {
    const response = await util.authorizedRequest(`user/profile/location/${longitudeEncoded}/${latitudeEncoded}`, {
      method: 'POST',
      bearerToken,
    });

    return response;
  } catch (error) {
    console.error('Failed to update location:', error);
    throw error;
  }
}

async function addFriend(bearerToken, friendId) {
  const response = await util.authorizedRequest(`user/add_friend/${friendId}`, {
    method: 'POST',
    bearerToken,
  });
  return response;
}

async function removeFriend(bearerToken, friendId) {
  const response = await util.authorizedRequest(`user/remove_friend/${friendId}`, {
    method: 'POST',
    bearerToken,
  });
  return response;
}

async function addDevice(bearerToken, device) {
  const response = await util.authorizedRequest(
    'user/add_device',
    {
      method: 'PUT',
      bearerToken,
    },
    { device_token: device },
  );
  return response;
}

async function removeDevice(bearerToken, device) {
  const response = await util.authorizedRequest(
    'user/remove_device',
    {
      method: 'PUT',
      bearerToken,
    },
    { device_token: device },
  );

  return response;
}
// Function below is handled thay was as authorizedRequest doesnt handle it properly, in case we have more than one create new utility function or refactor existing to handle both cases

async function sendReview(bearerToken, subject, message) {
  try {
    const requestBody = {
      subject,
      message,
    };

    const response = await fetch('https://v5j4xszs7i.execute-api.us-east-1.amazonaws.com/prod/user/review', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('Error response from server:', errorBody);
      throw new Error(`Server responded with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to send review:', error);
    throw error;
  }
}

async function getUserNotification(bearerToken, user_id) {
  const response = await util.authorizedRequest(`user/notifications/${user_id}`, {
    bearerToken,
  });
  return response;
}

async function getAllNotification(bearerToken) {
  const response = await util.authorizedRequest('user/notifications/~', {
    bearerToken,
  });
  return response;
}

async function verifyLogin(bearerToken) {
  try {
    const response = await util.authorizedRequest('user/verify-login', {
      method: 'POST',
      bearerToken,
    });
    return response;
  } catch (error) {
    console.error('Error verifying login:', error);
    throw error;
  }
}

const exports = {
  getProfile,
  allUsers,
  updateProfile,
  deleteUserData,
  updateAddress,
  getPastAddresses,
  getReferalLink,
  getFriendsProfile,
  incrementReferralCount,
  updateLocation,
  addFriend,
  removeFriend,
  addDevice,
  removeDevice,
  sendReview,
  getUserNotification,
  getAllNotification,
  verifyLogin,
};

export {
  getProfile,
  allUsers,
  updateProfile,
  deleteUserData,
  updateAddress,
  getPastAddresses,
  getReferalLink,
  getFriendsProfile,
  incrementReferralCount,
  updateLocation,
  addFriend,
  removeFriend,
  addDevice,
  removeDevice,
  sendReview,
  getUserNotification,
  getAllNotification,
  verifyLogin,
};
export default exports;
