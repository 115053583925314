import React, { useEffect, useState, useContext } from 'react';
import { IonContent, IonList, IonMenuToggle, IonItem, IonLabel, IonBadge, IonAvatar } from '@ionic/react';
import { ChannelRepository, MessageRepository } from '@amityco/ts-sdk';
import './RecentChats.scss';
import { AuthContext } from '../authentication/AuthContext';
import { allUsers } from '../../global/request/user';
import FoodFightIcon from '../../assets/foodfight_red_primary_05.png';
import { RecentChatItemLoader } from '../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

const RecentChats = ({ handleChannelClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [channels, setChannels] = useState([]);
  const [channelMembers, setChannelMembers] = useState({});
  const [allUsersData, setAllUsersData] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [messages, setMessages] = useState({});
  const authCtx = useContext(AuthContext);

  const cleanAndSetChannels = newChannels => {
    setChannels(prevChannels => {
      const combinedChannels = [...prevChannels, ...newChannels];
      const uniqueChannels = combinedChannels.reduce((acc, channel) => {
        if (!acc.some(existingChannel => existingChannel.channelId === channel.channelId)) {
          acc.push(channel);
        }
        return acc;
      }, []);

      return uniqueChannels;
    });
  };

  const fetchChannels = async (after = null) => {
    ChannelRepository.getChannels(
      {
        membership: 'member',
        sortBy: 'lastCreated',
        limit: 100,
        after,
      },
      ({ data, error, pagination }) => {
        if (error) {
          console.error('Error fetching channels:', error);
          return;
        }
        if (data) {
          cleanAndSetChannels(data);

          if (pagination?.hasNextPage) {
            fetchChannels(pagination.next);
          }
        }
      },
    );
  };

  const fetchChannelMembers = async channelId => {
    try {
      const result = await new Promise((resolve, reject) => {
        ChannelRepository.Membership.getMembers(
          { channelId, memberships: ['member'], sortBy: 'lastCreated' },
          ({ data: members, error, loading }) => {
            if (error) {
              console.error(`Error fetching members for channel ${channelId}:`, error);
              reject(new Error(`Error fetching members for channel ${channelId}`));
            } else if (loading) {
              console.log(`Loading members for channel ${channelId}...`);
            } else {
              const userIds = members?.map(member => member.userId) || [];
              console.log(`Fetched members for channel ${channelId}:`, userIds);
              resolve(userIds);
            }
          },
        );
      });
      return result;
    } catch (err) {
      console.error(`Unexpected error fetching members for channel ${channelId}:`, err);
      return [];
    }
  };

  const fetchAllUsers = async () => {
    try {
      setIsLoadingUsers(true);
      const response = await allUsers(authCtx.tokens.idToken);
      setAllUsersData(response);
    } catch (error) {
      console.error('Error fetching all users:', error);
    }
  };

  useEffect(() => {
    fetchChannels();
    fetchAllUsers();
    setIsLoading(false);
    setIsLoadingUsers(false);
  }, []);

  const fetchMessages = async subChannelId => {
    MessageRepository.getMessages(
      {
        subChannelId,
        limit: 1,
        reverse: true,
      },
      ({ data: fetchedMessages, error }) => {
        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }
        if (fetchedMessages.length > 0) {
          const latestMessage = fetchedMessages[0];
          setMessages(prevMessages => ({
            ...prevMessages,
            [subChannelId]: {
              text: latestMessage.data.text,
              createdAt: latestMessage.createdAt,
            },
          }));
        }
      },
    );
  };

  const formatTime = dateString => {
    const date = new Date(dateString);
    const today = new Date();
    const timeDifference = today - date; // Difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day

    if (timeDifference < oneDay) {
      return new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(date);
    }
    return new Intl.DateTimeFormat('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  };

  useEffect(() => {
    const fetchMembersForChannels = async () => {
      const memberPromises = channels.map(async channel => {
        const userIds = await fetchChannelMembers(channel.channelId);
        return { channelId: channel.channelId, userIds };
      });

      const members = await Promise.all(memberPromises);

      const memberMap = members.reduce((acc, { channelId, userIds }) => {
        acc[channelId] = userIds;
        return acc;
      }, {});

      setChannelMembers(memberMap);
    };

    const fetchMessagesForChannels = async () => {
      const messagePromises = channels.map(channel => fetchMessages(channel.channelId));
      await Promise.all(messagePromises);
    };

    if (channels.length > 0) {
      fetchMembersForChannels();
      fetchMessagesForChannels();
    }
  }, [channels]);

  const getUserNameById = userId => {
    if (isLoadingUsers) {
      return 'Loading...';
    }
    const foundUser = allUsersData.find(userRecord => userRecord.id === userId);
    return foundUser ? foundUser.username || 'Unknown User' : 'Unknown User';
  };

  const markAsRead = async channelId => {
    try {
      const channel = channels.find(ch => ch.channelId === channelId);
      if (channel?.markAsRead) {
        await channel.markAsRead();
        console.log(`Channel ${channelId} marked as read`);
        setChannels(prevChannels =>
          prevChannels.map(ch => (ch.channelId === channelId ? { ...ch, unreadCount: 0 } : ch)),
        );
      }
    } catch (error) {
      console.error(`Error marking channel ${channelId} as read:`, error);
    }
  };

  const handleChannelClickInternal = (channelId, userDisplayNames) => {
    markAsRead(channelId);
    handleChannelClick(channelId, userDisplayNames);
  };

  return (
    <IonContent className="recent-chats">
      <IonList className="recent-chat-list">
        {isLoading ? (
          <div>
            {Array.from({ length: 5 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
          </div>
        ) : (
          channels
            .sort((a, b) => (b.unreadCount || 0) - (a.unreadCount || 0))
            .map(channel => {
              const userIds = channelMembers[channel.channelId] || [];
              const filteredUserIds = userIds.filter(id => id !== authCtx?.idData?.sub);

              const userDisplayNames = filteredUserIds.map(userId => getUserNameById(userId)).join(', ');
              const latestMessage = messages[channel.channelId];
              const unreadCount = channel.unreadCount || 0;

              return (
                <IonMenuToggle key={channel.channelId} autoHide={false}>
                  <IonItem
                    className="recent-chat-items"
                    button
                    lines="full"
                    onClick={() => {
                      if (userDisplayNames !== '') {
                        handleChannelClickInternal(channel.channelId, userDisplayNames, isLoadingUsers);
                      }
                    }}
                  >
                    <IonAvatar slot="start" className="msg-avatar">
                      <img src={FoodFightIcon} alt="chat avatar" />
                    </IonAvatar>
                    <IonLabel className={unreadCount > 0 ? 'bold-label' : ''}>
                      <h3>{userDisplayNames || 'Loading...'}</h3>
                      {latestMessage && <p>{latestMessage.text}</p>}
                    </IonLabel>

                    {/* Right-aligned container for badge and time */}
                    <div className="right-content">
                      {unreadCount > 0 && (
                        <IonBadge color="danger" className="recent-badge">
                          {unreadCount}
                        </IonBadge>
                      )}
                      {latestMessage && <p className="msg-time">{formatTime(latestMessage.createdAt)}</p>}
                    </div>
                  </IonItem>
                </IonMenuToggle>
              );
            })
        )}
      </IonList>
    </IonContent>
  );
};

export default RecentChats;
